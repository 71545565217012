import React, {useEffect, useRef, useState} from 'react';
import cancelEvent from "./helpers/cancelEvent";

const PasswordInputField = ({
                                place,
                                pasVal,
                                setPasVal,
                                Cwrap,
                                Cinput,
                                CinputFieldBorder,
                                CtoggleVisible,
                                Ctgico,
                                Cstealthy,
                                emp,
                                err,
                                cor
                            }) => {
    const [passwordVisible, setPasswordVisible] = useState(false);
    const inputRef = useRef(null);
    const [em, setEm] = useState(true);

    useEffect(() => {
        // Добавляем скрытые "фиктивные" поля для обхода автозаполнения
        const input = inputRef.current;
        input.type = 'password';
        input.setAttribute('required', '');
        input.name = 'notsearch_password';
        input.autocomplete = 'off';

        const stealthy = document.createElement('input');
        stealthy.classList.add(Cstealthy);
        stealthy.tabIndex = -1;
        stealthy.type = 'password';

        input.parentElement.prepend(stealthy);
        input.parentElement.insertBefore(stealthy.cloneNode(), input.nextSibling);
    }, [Cstealthy]);

    useEffect(() => {
        if (inputRef.current.innerText.length === 0) {
            setEm(true)
        }
    }, []);

    const handleVisibilityClick = (e) => {
        cancelEvent(e);
        setPasswordVisible(!passwordVisible);
        if (inputRef.current) {
            inputRef.current.type = passwordVisible ? 'password' : 'text';
        }
    };

    const handleInput = (e) => {
        if (e.target.value.length === 0) {
            setEm(true);
        } else {
            setEm(false);
        }
        setPasVal(e.target.value);
    }

    return (
        <div className={`${Cwrap}`}>
            <input className={Cstealthy} tabIndex="-1" type="password"/>
            <input
                ref={inputRef}
                type="password"
                name="notsearch_password"
                autoComplete="off"
                required
                className={`${Cinput} ${em ? emp : ''} ${cor ? '' : err}`}
                onChange={handleInput}
                dir="auto"
            />
            <input className={Cstealthy} tabIndex="-1" type="password"/>

            <div className={CinputFieldBorder}></div>
            <label>
                <span>{place}</span>
            </label>

            <span className={CtoggleVisible} onClick={handleVisibilityClick}>
                <span className={Ctgico}>
                  {passwordVisible ? String.fromCharCode(parseInt('e959', 16)) : String.fromCharCode(parseInt('e958', 16))}
                </span>
            </span>
        </div>
    );
};

export default PasswordInputField;