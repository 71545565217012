import React, { useState, useEffect } from 'react';

/**
 * Компонент для ввода кода подтверждения
 * @param {Object} props - Параметры компонента
 * @param {number} props.length - Длина кода подтверждения
 * @param {function} props.onFill - Callback, который вызывается, когда код полностью введён
 */
const CodeInputField = ({ length, onFill, varClass, erClass, preload, error, setError }) => {
    // Введённое пользователем значение
    const [value, setValue] = useState('');

    // Обработчик изменения текста в input
    const handleChange = (e) => {
        setError(false); // Убираем ошибку при каждом вводе
        const inputValue = e.target.value.replace(/\D/g, ''); // Удаляем все нецифровые символы
        const newValue = inputValue.slice(0, length); // Ограничиваем длину значения
        setValue(newValue); // Устанавливаем новое значение

        // Если длина введённого кода совпадает с требуемой, вызываем onFill
        if (newValue.length === length) {
            onFill(newValue);
        }
    };

    // Эффект для очистки ошибки, если поле ввода стало пустым
    useEffect(() => {
        if (value.length === 0) {
            setError(false); // Убираем ошибку, если поле ввода очистилось
        }
    }, [value]);

    return (
        <input
            type="tel" // Устанавливаем тип поля ввода как телефонное для более удобного ввода цифр
            required
            autoComplete="off" // Отключаем автозаполнение
            className={`${varClass} ${error ? erClass : ''}`} // Добавляем класс 'error', если есть ошибка
            value={value} // Устанавливаем текущее значение поля
            onChange={handleChange} // Обработчик ввода, вызванный при изменении значения
            disabled={preload}
        />
    );
};

export default CodeInputField;
