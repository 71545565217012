import React, {createContext, useCallback, useEffect, useRef, useState} from 'react';
import CountryDataSet from "../assets/data/CountryDataSet.json";


export const MainContext = createContext();

export const MainProvider = ({children}) => {
    const [selectedCountry, setSelectedCountry] = useState({
        flag: 'https://web.telegram.org/k/assets/img/emoji/1f1f7-1f1fa.png',
        name: 'Россия',
        phone_code: '+7',
        alt: `RU`,
        pattern: "--- --- ----",
        number: ""
    });
    const [phoneValue, setPhoneValue] = useState('');
    const [countries, setCountries] = useState([]);
    const inputPhoneRef = useRef(null);
    const [acc, setAcc] = useState([]);

    const focusInputPhone = useCallback(() => {
        if (inputPhoneRef.current) {
            inputPhoneRef.current.focus();
            // Создаем новый Range
            const range = document.createRange();
            const selection = window.getSelection();

            // Устанавливаем Range на конец содержимого
            range.selectNodeContents(inputPhoneRef.current);
            range.collapse(false); // Ставим курсор в конец

            // Очищаем текущее выделение и устанавливаем новое
            selection.removeAllRanges();
            selection.addRange(range);
        }
    }, [inputPhoneRef]);

    function editPhone(e) {
        console.log('START', e.target.innerText);
        let copyC = {...selectedCountry};
        if (!e.target.innerText.includes('+')) {
            if (e.target.innerText.length === 1) {
                e.target.innerText = `+${e.target.innerText.trim()}`;
                copyC.number = '';
            }
            const findCbyCode = countries.filter(country => e.target.innerText.includes(country.phone_code));
            copyC.phone_code = e.target.innerText;
            if (findCbyCode.length > 0) {
                copyC = {...findCbyCode.slice(-1)[0]};
            } else {
                copyC.name = '';
                copyC.pattern = '';
            }
        }
        if (e.target.innerText.length < `${selectedCountry.phone_code} ${selectedCountry.number}`.length) {
            console.log('DELETED', e.target.innerText.length, `${selectedCountry.phone_code} ${selectedCountry.number}`.length, e.target.innerText.trim().length, selectedCountry.phone_code.length);
            if (!/\d/.test(e.target.innerText)) {
                e.target.innerText = '';
                copyC.name = '';
                copyC.phone_code = '';
                copyC.number = '';
                console.log('NULLED', e.target.innerText.trim().length, e.target.innerText.length, Boolean(''));
            } else if (e.target.innerText.includes(selectedCountry.phone_code)) {
                if (e.target.innerText.length > selectedCountry.phone_code.length) {
                    console.log(selectedCountry.number.slice(-1));
                    if (/^\d*$/.test(selectedCountry.number.slice(-1))) {
                        copyC.number = selectedCountry.number.slice(0, -1);
                        copyC.pattern = `-${selectedCountry.pattern}`;
                    } else if (selectedCountry.number.slice(-1) === ' ') {
                        copyC.number = selectedCountry.number.slice(0, -2);
                        copyC.pattern = `- ${selectedCountry.pattern}`;
                    }
                } else if ((e.target.innerText.length === selectedCountry.phone_code.length) || (e.target.innerText.length < selectedCountry.phone_code.length && e.target.innerText.length > 0)) {
                    copyC.number = '';
                    copyC.phone_code = selectedCountry.phone_code.slice(0, -1);
                    const findCbyCode = countries.filter(country => country.phone_code === copyC.phone_code);
                    console.log(findCbyCode);
                    if (findCbyCode.length > 0) {
                        copyC = {...findCbyCode[0]};
                    } else {
                        copyC.name = '';
                        copyC.pattern = '';
                    }
                }
            }
        } else if (e.target.innerText.length < 6 && !selectedCountry.phone_code) {
            const findCbyCode = countries.filter(country => e.target.innerText.includes(country.phone_code));
            console.log('INPUTVALUE', findCbyCode, e.target.innerText.trim().replace(' ', ''));
            copyC.phone_code = e.target.innerText;
            if (findCbyCode.length > 0) {
                copyC = {...findCbyCode.slice(-1)[0]};
            } else {
                copyC.name = '';
                copyC.pattern = '';
            }
            console.log('INPUTED', e.target.innerText.trim().length, e.target.innerText.length);
        } else if ((!/^\d*$/.test(e.target.innerText.slice(-1))) || (e.target.innerText.includes('\n'))) {
            e.target.innerText = e.target.innerText.slice(0, -1).replace('\n', '');
            copyC.number = selectedCountry.number;
        } else {
            copyC.number = e.target.innerText.replace(copyC.phone_code, '').replace(' ', '');
            if (selectedCountry.pattern.startsWith(' ')) {
                copyC.pattern = selectedCountry.pattern.slice(2);
                copyC.number = `${selectedCountry.number} ${e.target.innerText.slice(-1)}`;
                // e.target.innerText = `${e.target.innerText.slice(0, -1)} ${e.target.innerText.slice(-1)}`;
            } else if (selectedCountry.pattern.startsWith('-') || (selectedCountry.pattern.length === 0)) {
                copyC.pattern = copyC.pattern.slice(1);
            }
        }
        console.log(copyC);
        setSelectedCountry(copyC);
        setCursorToEnd(e.target);
    }

    function setCursorToEnd(element) {
        const range = document.createRange();
        const selection = window.getSelection();
        range.selectNodeContents(element);
        range.collapse(false); // false означает "в конец"
        selection.removeAllRanges();
        selection.addRange(range);
        element.focus(); // Устанавливаем фокус на элемент
    }

    function generatePatternWithDashes(pattern) {
        if (!pattern) {
            return '';
        }
        return `${pattern.replace(/X/g, '-')}`;
    };

    useEffect(() => {
        console.log('render maincontext');
        const fetchCountries = () => {
            try {
                const updatedCountries = CountryDataSet.map(country => ({
                    ...country,
                    pattern: generatePatternWithDashes(country.pattern),
                    number: ''
                }));
                setCountries(updatedCountries);
                console.log(updatedCountries[0]);
            } catch (error) {
                console.error('Ошибка при загрузке данных', error);
            }
        };
        fetchCountries();
    }, []);

    return (
        <MainContext.Provider value={{
            selectedCountry, setSelectedCountry, countries, setCountries, inputPhoneRef, focusInputPhone,
            phoneValue, setPhoneValue, editPhone, acc, setAcc
        }}>
            {children}
        </MainContext.Provider>
    );
};