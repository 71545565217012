import React, {useContext} from 'react';
import classes from "../styles/InputPhone.module.css";
import {MainContext} from "../context/MainContext";

const InputPhone = ({btnClick, pollForCode}) => {
    const {
        editPhone,
        inputPhoneRef,
        selectedCountry
    } = useContext(MainContext);

    const handleInputChange = (event) => {
        event.preventDefault();
        editPhone(event);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            console.log('Нажата клавиша Enter');
            btnClick(event, true);
        }
    };


    return (
        <div className={`${classes.inputField} ${classes.inputFieldPhone}`}>
            <div className={classes.inputFieldInput} dir="auto" data-no-linebreaks="1"
                 contentEditable={true} suppressContentEditableWarning={true}
                 inputMode="decimal"
                 data-left-pattern={selectedCountry.pattern}
                 style={{"--letter-spacing": "0"}}
                 ref={inputPhoneRef}
                 onInput={handleInputChange}
                 onKeyDown={handleKeyDown}
            >
                {selectedCountry.phone_code ? `${selectedCountry.phone_code} ${selectedCountry.number}` : `${selectedCountry.phone_code}${selectedCountry.number}`}
            </div>
            <div className={classes.inputFieldBorder}></div>
            <label>
                <span className={classes.i18n}>Номер телефона</span>
            </label>
        </div>
    );
};

export default InputPhone;