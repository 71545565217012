import React from 'react';
import classes from './../styles/SignQR.module.css';

const SignQr = ({handleSetStage}) => {

    return (
        <div>
            <div className={`${classes.tabsTab} ${classes.pageSignQR} ${classes.active}`}
                 data-transition-timeout="90"
            >
                <div className={`${classes.container} ${classes.centerAlign}`}>
                    <div className={classes.authImage}>
                        <canvas width="480" height="480" className="qr-canvas"></canvas>
                    </div>
                    <h4 className={classes.i18n}>Быстрый вход по QR-коду</h4>
                    <ol className="qr-description">
                        <li><span className={classes.i18n}>Откройте Telegram с телефона</span></li>
                        <li><span className={classes.i18n}>Откройте <b>Настройки</b> &gt; <b>Устройства</b> &gt; <b>Подключить устройство</b>.</span>
                        </li>
                        <li><span className={classes.i18n}>Для подтверждения направьте камеру телефона на этот экран.</span>
                        </li>
                    </ol>
                    <div className="input-wrapper">
                        <button className="btn-primary btn-secondary btn-primary-transparent primary rp"
                                onClick={() => handleSetStage('phone')}
                        >
                            <div className="c-ripple"></div>
                            <span className="i18n">Вход по номеру телефона</span></button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignQr;