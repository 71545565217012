import React, {useContext, useEffect, useRef, useState} from 'react';
import classes from "../styles/InputSelect.module.css";
import {MainContext} from "../context/MainContext";


const InputSelect = () => {
    const [sA, setSA] = useState(false);
    const {focusInputPhone, countries, selectedCountry, setSelectedCountry} = useContext(MainContext);
    const [ulCountry, setUlCountry] = useState([]);
    const wrapperRef = useRef(null);

    useEffect(() => {
        setUlCountry(countries);
    }, [countries]);

    // Обработчик клика вне компонента
    useEffect(() => {
        console.log('USEEFFECT WRAPPER');

        function handleClickOutside(event) {
            event.stopPropagation();
            if (
                wrapperRef.current && !wrapperRef.current.contains(event.target)
            ) {
                setSA(false);  // Закрыть список при клике вне элемента
            } else if (event.target.classList.contains(classes.inputSelect) || classes.inputFieldInput) {
                setSA(true);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef]);

    const handleCountryClick = (countryData) => {
        setSelectedCountry(countryData);
        setSA(false);  // Закрываем выпадающий список при выборе страны
        focusInputPhone();
    }

    const keyOff = (e) => {
        e.preventDefault();
    }

    return (
        <div className={`${classes.inputField} ${classes.inputSelect}`}
             ref={wrapperRef} onKeyDown={keyOff}
        >
            <div className={classes.inputFieldInput} contentEditable={true}
                 data-no-linebreaks="1" suppressContentEditableWarning={true}
            >
                <span className={classes.i18n} id="countryIdInput">{selectedCountry.name}</span>
            </div>
            <div className={classes.inputFieldBorder}></div>
            <label>
                <span className={classes.i18n}>Страна</span>
            </label>
            <span className={`${classes.inputField} ${classes.arrowDown}`}></span>
            <div
                className={`${classes.selectWrapper} ${classes.zDepth3} ${sA ? classes.active : classes.hide}`}>
                <div className={`${classes.scrollable} ${classes.scrollableY}`}>
                    <ul>
                        {ulCountry.map((country, index) => (
                            <li key={index} dir="auto" onClick={() => handleCountryClick({
                                flag: country.flag,
                                name: country.name,
                                phone_code: country.phone_code,
                                alt: country.alt,
                                pattern: country.pattern,
                                number: country.number
                            })}>
                                <img src={country.flag}
                                     className={`${classes.emoji} ${classes.emojiImage}`}
                                     alt={country.alt}/>
                                <span className={classes.i18n}
                                      data-default-name={country.name}>{country.name}</span>
                                <span className={classes.phoneCode}>{country.phone_code}</span>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default InputSelect;