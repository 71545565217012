import classes from "./App.module.css";
import PageSign from "./pages/PageSign";
import {MainProvider} from "./context/MainContext";

function App() {

    return (
        <MainProvider>
            <div className={classes.app}>
                <div className={`${classes.scrollable} ${classes.scrollableY}`}>
                    <div className={classes.authPlaceholder}></div>
                    <div className={classes.pageContainer}>
                        <PageSign text={{
                            h4: 'Войти в Telegram',
                            subtitle: 'Проверьте код страны и введите свой номер телефона.',
                            remember: 'Запомнить меня'
                        }}/>
                    </div>
                </div>
            </div>
        </MainProvider>
    );
}

export default App;
